import React from 'react';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import "bootstrap/dist/css/bootstrap.min.css";
import { useLanguage } from "../utils/LanguageContext";
import { Link } from 'react-router-dom';
function Sitemap() {
    const { language, changeLanguage, translate } = useLanguage();
    return (
        <div>
            <Navbar changeLanguage={changeLanguage} currentLanguage={language} />
            <section className="py-5 padd" id="sitemap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className='font-18px heading-boder-b'>{language === "en" ? "Sitemap" : "ਸਾਈਟਮੈਪ"}</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sitemap-links ps-5">
                                <ul>
                                    {menuItems?.map((item, index) => {
                                        return (
                                            <li key={index}><Link to={item?.url}><i className={item?.icon}></i>{language === "en" ? item?.nameEn : item?.namePu}</Link></li>
                                        )
                                    })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
export default Sitemap;

const menuItems = [
    { nameEn: "Home", namePu: "घर", url: "/", icon: "bi bi-house-door" },
    { nameEn: "Latest News", namePu: "ताजा खबर", url: "/news", icon: "bi bi-newspaper" },
    { nameEn: "Videos", namePu: "वीडियो", url: "/videos", icon: "bi bi-camera-video" },
    // { nameEn: "Shorts", namePu: "शॉर्ट्स", url: "/shorts", icon: "bi bi-film" },
    { nameEn: "Career", namePu: "आजीविका", url: "/career", icon: "bi bi-briefcase" },
    { nameEn: "About Us", namePu: "हमारे बारे में", url: "/about-us", icon: "bi bi-info-circle" },
    { nameEn: "News & Press Releases", namePu: "समाचार एवं प्रेस विज्ञप्तियाँ", url: "/press-releases", icon: "bi bi-bullhorn" },
    { nameEn: "Advertise", namePu: "विज्ञापित", url: "/advertise", icon: "bi bi-megaphone" },
    { nameEn: "Investor", namePu: "इन्वेस्टर", url: "/investor", icon: "bi bi-bar-chart-line" },
    { nameEn: "Contact Us", namePu: "हमसे संपर्क करें", url: "/contact-us", icon: "bi bi-telephone" },
    { nameEn: "Disclaimer", namePu: "अस्वीकरण", url: "/disclaimer", icon: "bi bi-exclamation-circle" },
    // { nameEn: "Gallery", namePu: "गैलरी", url: "/gallery", icon: "bi bi-images" },
    { nameEn: "Privacy Policy", namePu: "गोपनीयता नीति", url: "/privacy-policy", icon: "bi bi-lock" },
    { nameEn: "Terms & Conditions", namePu: "नियम एवं शर्तें", url: "/terms-and-conditions", icon: "bi bi-gavel" }
];

