import React from "react";
import "../style/style.css";
import { Link } from "react-router-dom";
import { useLanguage } from "../utils/LanguageContext";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import img from "../assets/images/_RA99161.webp";
import SEO from "../components/common/Seo";
import logo from '../assets/images/my-hinditv-logo.webp';

const AboutUs = () => {
  const { changeLanguage, language, translate } = useLanguage();
  return (
    <div>
      <SEO title="My Hindi TV - हिंदी न्यूज़, ताजा ख़बरें और Updates" description="My Hindi TV पर पढ़ें ताजातरीन हिंदी समाचार, ब्रेकिंग न्यूज़, और पूरी जानकारी। जानें हमसे जुड़ी हर ख़बर।"/>
      <Navbar changeLanguage={changeLanguage} currentLanguage={language} />
      <section className="live-news padd features latest-news about-us">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className='fs-5 text-uppercase fw-bold'>{translate("navAbout")}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p style={{ textAlign: 'justify' }}>
              <Link className="about-mylogo-info" to="/" >
                <img loading="lazy" src={logo} className="img-fluid" alt="MyPunjabi Logo" />
              </Link>
              {translate("aboutContent")}</p>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-3 pb-md-5 pb-xl-8">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="bg-style-profilediv">
                <div class="row align-items-md-center">
                  <div class="col-md-5 text-center">
                    <img loading="lazy" src={img} alt="img" />
                  </div>
                  <div class="col-md-7">
                    <div class="card-body p-3">
                      <span className="dirictor-div-style">{language === "hn" ? "निदेशक मीडिया" : "DIRECTOR MEDIA"}</span>
                      <h2 class="card-title h4 mb-3 pt-2">
                        {language === "hn" ? "गुरनितिका संधू" : "GURNITIKA SANDHU"}
                      </h2>
                      <p class="card-text pb-3">
                        {language === "hn" ? "वर्षों के अनुभव और गहन उद्योग ज्ञान के साथ, हमारे पास सफलता का एक सिद्ध ट्रैक रिकॉर्ड है और हम आगे रहने के लिए खुद को प्रेरित कर रहे हैं।" : "With years of experience and deep industry knowledge, we have a proven track record of success and are pushing ourselves to stay ahead of the curve."}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
