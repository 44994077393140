import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const SEO = ({ title = "", description = "", keywords = "", schema = "" }) => {
  const { pathname } = useLocation();
  console.log('Schema:', schema);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title || "MyHindi"}</title>
      <meta
        name="description"
        content={description || "Best News channel in the area"}
      />
      <meta property="og:title" content={title || "MyHindi"} />
      <meta property="og:description" content={description || "Best News channel in the are"} />
      <link rel="canonical" href={`https://myhinditv.com${pathname}`} />
      {keywords && <meta name="keywords" content={keywords} />}
      {schema && (
        <script defer type="application/ld+json">
          {schema}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
