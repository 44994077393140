import React from 'react'
import { useEffect } from 'react'
import { useLanguage } from '../utils/LanguageContext'
import Live from '../components/homePageComponents/Live'
import LatestNews from '../components/homePageComponents/LatestNews'
import Navbar from '../components/common/Navbar'
import Logos from '../components/homePageComponents/Logos'
import Footer from '../components/common/Footer'
import BreakingNews from '../components/homePageComponents/BreakingNews'
import NewsVideos from '../components/homePageComponents/NewsVideos'
import SportsNews from '../components/homePageComponents/CategoryNews'
import { useLocation, useNavigate } from 'react-router-dom'
import Categories from '../components/homePageComponents/Categories'
import SEO from '../components/common/Seo'
const Home = () => {
  const { changeLanguage, language } = useLanguage();
  const location = useLocation()
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('scrollTo') === 'live-news-section') {
      const liveNewsSection = document.getElementById('live-news-section');
      if (liveNewsSection) {
        window.scrollTo({
          top: liveNewsSection.offsetTop,
          behavior: 'smooth'
        });
      }
    }
  }, [location.search]);
  useEffect(() => {
    const handleScroll = () => {
      const params = new URLSearchParams(location.search);
      if (params.get('scrollTo') === 'live-news-section') {
        navigate('/');
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.search, navigate]);

  return (
    <>
      <SEO title='हिंदी न्यूज़, Breaking News in Hindi, Latest News in Hindi | My Hindi TV' description='पढ़ें ताजा हिंदी समाचार, ब्रेकिंग न्यूज़, और लेटेस्ट अपडेट्स। जानें इंडिया की सभी ताजातरीन ख़बरें और वीडियो सिर्फ My Hindi TV पर।'
        schema={`
      [
      {
         "@context": "https://schema.org/",
         "@type": "WebSite",
         "name": "My Hindi TV",
         "url": "https://www.myhinditv.com/",
         "potentialAction": {
         "@type": "SearchAction",
         "target": "https://www.myhinditv.com/{search_term_string}",
         "query-input": "required name=search_term_string"
        }
       }
       ,
        {         
        "@context": "https://schema.org/",         
          "@type": "LocalBusiness",         
          "@id": "#LocalBusiness",         
          "url": "https://www.myhinditv.com/",          
          "legalName": "My Hindi TV",
          "name": "My Hindi TV",
          "description": "",
          "image": "https://www.myhinditv.com/static/media/my-hinditv-logo.7093900faa18301d0cfb.webp",
          "logo": "https://www.myhinditv.com/static/media/my-hinditv-logo.7093900faa18301d0cfb.webp",
          "telephone": "+91 8968700675",
       
          "email": "helpdeskmyhinditv@gmail.com",
          "address": {             
          "@type": "PostalAddress",             
          "streetAddress": "B-70, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055",             
          "addressLocality": "Mohali",             
          "addressRegion": "Punjab",             
          "addressCountry": "India",             
          "postalCode": "160055"        
        }
        }
        },
       {         
          "@context": "https://schema.org/",         
          "@type": "Organization",         
          "@id": "#Organization",         
          "url": "https://www.myhinditv.com/",         
          "legalName": "My Hindi TV",         
          "name": "My Hindi TV",         
          "description": "Myhinditv.com is a popular Hindi news website. It offers authentic news from India and around the world, along with     important information related to daily life.",         
          "image": "https://www.myhinditv.com/static/media/my-hinditv-logo.7093900faa18301d0cfb.webp",         
          "logo": "https://www.myhinditv.com/static/media/my-hinditv-logo.7093900faa18301d0cfb.webp",         
          "telephone": "+91 8968700675",         
          "email": "helpdeskmyhinditv@gmail.com",         
          "address": {             
          "@type": "PostalAddress",             
          "streetAddress": "B-70, Phase 7, Sector 74",             
          "addressLocality": "SAS Nagar",             
          "addressRegion": "Punjab",             
          "addressCountry": "IN",             
          "postalCode": "160055"        
      },         
          "sameAs": [             
          "https://www.facebook.com/myhinditvofficial/",          
          "https://www.youtube.com/@My_HindiTV",             
          "https://www.instagram.com/myhindi.tv/",           
          "https://x.com/myhinditv_"       
      ] 
      }
      ]
      `}
      />
      <Navbar changeLanguage={changeLanguage} currentLanguage={language} />
      <div>
        <Categories />
        <BreakingNews />
        <div id='live-news-section'>
          <Live />
        </div>
        <LatestNews />
        <SportsNews />
        <NewsVideos />
        <Logos />
        <Footer />
      </div>

    </>
  )
}

export default Home
